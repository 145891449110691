import React from "react";
import "./TalkToUs.css";

export default function TalkToUs() {
  return (
    <section className="">
      <span className="sec-title d-block text-center mb-5">Talk to us.</span>
      <div className="talk-to-us-sec">
        <div className="row">
          <div class="col-md-4">
            <img src="/images/round-animal.png" className="img-fluid" />
          </div>
          <div className="col-md-8 d-flex align-items-center">
            <div>
              <h5 className="title">Contact person name</h5>
              <p className="sub-title mt-3">Jungle&apos;s Head</p>
              <p className="desc">
                We are dedicated to finding the right solutions for you
              </p>
              <a href="#" className="btn theme-btn-primary">
                Get in touch
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
