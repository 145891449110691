import { Col, Row } from "react-bootstrap";
import "./SingleWorksDesign2.css";
import {
  get_single_work_design_2_item_class,
  get_windows_dimention,
} from "../../helper";
import { APP_IMG_BASE_URL } from "../../ApiRoutes";
import VideoPlayer from "../sub-components/VideoPlayer";
import { useState } from "react";
import CenterLoader from "./../sub-components/CenterLoader";

export default function SingleWorksDesign2(props) {
  const project = props.project;
  const dimenstion = get_windows_dimention();

  if (project != null && project.length > 0) {
    if (dimenstion.width >= 768) {
      return project.map((item, index) => {
        return (
          <Col className={`${get_single_work_design_2_item_class(index)}`}>
            {item.media_type == "image" && (
              <SingleWorksDesign2ImageItem item={item} />
            )}
            {item.media_type == "video" && (
              <SingleWorksDesign2VideoItem
                item={item}
                videoControls={item.video_controls}
              />
            )}
            {item.media_type == "text" && (
              <SingleWorksDesign2TextItem item={item} />
            )}
          </Col>
        );
      });
    } else {
      return project.map((item, index) => {
        return (
          <Col className="col-12">
            <div class="item">
              {item.title.trim() != "" && <h3>{item.title.trim()}</h3>}
              {item.media_type == "image" && (
                <>
                  <img
                    src={`${APP_IMG_BASE_URL}/${item.thumb}`}
                    className="img"
                    alt="img"
                  />
                  {item.desc.trim() !== "" && (
                    <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
                  )}
                </>
              )}

              {item.media_type == "video" && (
                <>
                  <VideoPlayer
                    classes="work-video"
                    src={`${item.thumb}`}
                    videoControls={item.video_controls}
                  />
                  {item.desc.trim() !== "" && (
                    <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
                  )}
                </>
              )}

              {item.media_type == "text" && (
                <>
                  <div class="item">
                    {item.title.trim() != "" && <h3>{item.title.trim()}</h3>}
                    <div className="edit-text-item">
                      {item.desc.trim() !== "" && (
                        <div
                          dangerouslySetInnerHTML={{ __html: item.desc }}
                        ></div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </Col>
        );
      });
    }
  } else {
    return <CenterLoader />;
  }
}

function SingleWorksDesign2ImageItem(props) {
  const { item } = props;
  return (
    <div class="item">
      {item.title.trim() != "" && <h3>{item.title.trim()}</h3>}
      <img
        src={`${APP_IMG_BASE_URL}/${item.thumb}`}
        className="img"
        alt="img"
      />
      {item.desc.trim() !== "" && (
        <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
      )}
    </div>
  );
}

function SingleWorksDesign2VideoItem(props) {
  const { item } = props;
  return (
    <div class="item video-item">
      {item.title.trim() != "" && <h3>{item.title.trim()}</h3>}
      <VideoPlayer
        classes="work-video"
        src={`${item.thumb}`}
        videoControls={item.video_controls}
      />
      {item.desc.trim() !== "" && (
        <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
      )}
    </div>
  );
}

function SingleWorksDesign2TextItem(props) {
  const { item } = props;
  return (
    <div class="item">
      {item.title.trim() != "" && <h3>{item.title.trim()}</h3>}
      <div className="edit-text-item">
        {item.desc.trim() !== "" && (
          <div dangerouslySetInnerHTML={{ __html: item.desc }}></div>
        )}
      </div>
    </div>
  );
}
