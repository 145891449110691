import React, { useState } from "react";
import ReactPlayer from "react-player";
import "./VideoPlayer.css";
import { Col } from "react-bootstrap";

export default function VideoPlayer(props) {
  console.log("video controls", props.videoControls);
  return (
    <div className={`${props.classes} position-relative ct-video-player`}>
      {props.videoControls == 1 ? (
        <DefaultVideoPlayer src={props.src} />
      ) : (
        <PlainVideoPlayer src={props.src} />
      )}
    </div>
  );
}

function PlainVideoPlayer(props) {
  const [playState, setPlayState] = useState(true);

  function toggleVideo(event) {
    if (playState) {
      setPlayState(false);
      event.target.pause();
    } else {
      event.target.play();
      setPlayState(true);
    }
  }

  return (
    <>
      <div className="position-relative">
        <span class="play-btn">
          {playState ? <img src={`/play.png`} /> : <img src={`/pause.png`} />}
        </span>
        <video
          width="100%"
          height="100%"
          loop="true"
          muted="muted"
          controlsList="nodownload"
          data-play="0"
          onClick={(event) => {
            toggleVideo(event);
          }}
          autoPlay
        >
          <source src={props.src} type="video/mp4" />
        </video>
      </div>
    </>
  );
}

function DefaultVideoPlayer(props) {
  return (
    <video
      width="100%"
      height="100%"
      loop="true"
      controlsList="nodownload"
      data-play="0"
      controls
    >
      <source src={props.src} type="video/mp4" />
    </video>
  );
}
