import { useNavigate, useParams } from "react-router";

import React, { useEffect, useState } from "react";

import { Row } from "react-bootstrap";
import "./WorkCategoryPage.css";
import { get_grid_row_css } from "../../helper";
import { CATEGORY_PROJECT_LIST_URL } from "../../ApiRoutes";
import axios from "axios";
import Loader from "../sub-components/Loader";
import CenterLoader from "../sub-components/CenterLoader";

const WorkPageBreadCrumb = React.lazy(() =>
  import("../sub-components/WorkPageBreadCrumb")
);

const WorksDesign1 = React.lazy(() => import("../design/WorksDesign1"));

export default function WorkCategoryPage(props) {
  const { category } = useParams();
  const [categorySlug, setCategorySlug] = useState(category);
  const [isLoading, setIsLoading] = useState(true);
  const navigateTo = useNavigate();

  const [categoryProjectItems, setCategoryProjectItems] = useState([]);

  useEffect(() => {
    axios.get(`${CATEGORY_PROJECT_LIST_URL}${category}`).then((response) => {
      setCategoryProjectItems(response.data.data);
      setIsLoading(false);
    });
  }, [category]);

  let css_class = ".work-grid-section .grid-sec-no-";
  let html_class = "work-grid-section grid-sec-no-";

  if (isLoading == false && categoryProjectItems == null) {
    navigateTo("/404");
    return 0;
  }
  return (
    <>
      <React.Suspense fallback={<CenterLoader />}>
        <WorkPageBreadCrumb />
      </React.Suspense>

      {!isLoading ? (
        <section className="work-grid">
          <Row>
            {categoryProjectItems.map((arr, index) => {
              return (
                <section
                  key={`work-grid-sec-${index}`}
                  className={`${html_class}${index}`}
                >
                  <style>{`${css_class}${index}{
            grid-template-rows: ${get_grid_row_css(arr.length)}
                 }`}</style>
                  <React.Suspense fallback={<CenterLoader />}>
                    <WorksDesign1
                      key={`work-grid-inner-${index}`}
                      items={arr}
                      category={category}
                    />
                  </React.Suspense>
                </section>
              );
            })}
          </Row>
        </section>
      ) : (
        <CenterLoader />
      )}
      {!isLoading && categoryProjectItems.length <= 0 && (
        <h5>No design found</h5>
      )}
    </>
  );
}
