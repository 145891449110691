import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/sub-components/Header";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import "./theme.css";
import HomePage from "./components/pages/HomePage";
import AboutPage from "./components/pages/AboutPage";
import WorksPage from "./components/pages/WorksPage";
import Footer from "./components/sub-components/Footer";
import WorkCategoryPage from "./components/pages/WorkCategoryPage";
import SingleWorkPage from "./components/pages/SingleWorkPage";
import "./App.css";
import SingleWorksDesign2 from "./components/design/SingleWorksDesign2";
import SingleWorksDesign3 from "./components/design/SingleWorksDesign3";
import { Row } from "react-bootstrap";
import PageNotFound from "./components/pages/PageNotFound";

function App() {
  return (
    <div className="App">
      <main>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" exact element={<HomePage />}></Route>
            <Route path="/about-us" exact element={<AboutPage />}></Route>
            <Route path="/work" exact element={<WorksPage />}></Route>
            <Route
              path="/work/:category"
              element={<WorkCategoryPage />}
            ></Route>
            <Route
              path="/work/:category/:project"
              element={<SingleWorkPage />}
            ></Route>
            <Route
              path="/test"
              element={
                <div className="single-work-page-design-3">
                  <Row>
                    <SingleWorksDesign3 />
                  </Row>
                </div>
              }
            ></Route>
            <Route path="/404" element={<PageNotFound />}></Route>
            <Route path="*" element={<PageNotFound />}></Route>
          </Routes>
        </BrowserRouter>
      </main>
      <Footer />
    </div>
  );
}

export default App;
