import React from "react";
import "./SingleProjectSummary.css";

export default function SingleProjectSummary(props) {
  const { content } = { ...props };

  return (
    <section className="single-project-summary">
      <div dangerouslySetInnerHTML={{ __html: content }}></div>
    </section>
  );
}
