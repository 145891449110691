/*
 * TopTitleVidComp.js
 * Has a top title of h1 and a video below of it
 */

import { useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";

export default function TopTitleVidComp(props) {
  const videoTag = useRef();

  return (
    <section>
      <Row>
        <Col>
          <div class="section-wrap" id="hero-sec">
            <h1>
              Animal Is An Independed <br />
              Creative Agency With A Focus <br />
              On Creating <ins>Work</ins> Based On <br />
              Design And Insights.
            </h1>

            <video width="100%" height="100%" loop autoPlay muted>
              <source src="intro-vid.mp4" type="video/mp4" />
            </video>
          </div>
        </Col>
      </Row>
    </section>
  );
}
