import WorkPageBreadCrumb from "../sub-components/WorkPageBreadCrumb";
import { useNavigate } from "react-router";
import { useEffect } from "react";

export default function WorksPage() {
  const navigate = useNavigate();
  useEffect(function () {
    navigate("/work/all");
  });
}
