export default function SocialShareLinks(props) {
  return (
    <div className="widget">
      <div className="social-link-widget">
        <ul className="list-style-none px-0">
          <li>
            <a href="#" className="text-decoration-none">
              Instagaram
            </a>
          </li>
          <li>
            <a href="#" className="text-decoration-none">
              Facebook
            </a>
          </li>
          <li>
            <a href="#" className="text-decoration-none">
              X
            </a>
          </li>
          <li>
            <a href="#" className="text-decoration-none">
              LinkedIn
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
