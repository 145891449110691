import React, { Suspense, lazy, useEffect, useState } from "react";
import SingleWorkDesign1, {
  SingleWorkDesign1ForEvenItems,
  SingleWorkDesign1ForMobile,
  SingleWorkDesign1ForOddItems,
} from "../design/SingleWorkDesign1";
import { useNavigate, useParams } from "react-router";
import { Col, Row } from "react-bootstrap";
import { get_windows_dimention } from "../../helper";

import axios from "axios";
import { CATEGORY_SINGLE_PROJECT_URL } from "../../ApiRoutes";
import SingleWorksDesign3 from "../design/SingleWorksDesign3";
import TalkToUs from "../TalkToUs";
import SingleWorksDesign4 from "../design/SingleWorksDesign4";
import SingleWorksDesign5 from "../design/SingleWorksDesign5";
import SingleProjectSummary from "../sub-components/SingleProjectSummary";
import "./SingleWorkPage.css";
import CenterLoader from "../sub-components/CenterLoader";
const SingleWorksDesign2 = lazy(() => import("../design/SingleWorksDesign2"));

const SingleWorkPageBreadCrumb = React.lazy(() =>
  import("../sub-components/SingleWorkPageBreadCrumb")
);

export default function SingleWorkPage() {
  const params = useParams();
  const navigateTo = useNavigate();

  const category_slug = params.category;
  const project_slug = params.project;

  const dimenstion = get_windows_dimention();

  const [project, setProject] = useState({ gallery: [] });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`${CATEGORY_SINGLE_PROJECT_URL}/${category_slug}/${project_slug}`)
      .then((response) => {
        setProject(response.data.data);
        setIsLoading(false);
      });
    window.scrollTo(0, 0);
  }, []);

  if (project == null) {
    navigateTo("/404");
    return 0;
  }

  const data = project.gallery;

  if (isLoading) {
    <CenterLoader />;
  } else {
    if (project.design_type == 1) {
      if (dimenstion.width >= 768) {
        return (
          <>
            <React.Suspense fallback={<CenterLoader />}>
              <SingleWorkPageBreadCrumb
                category={project.category_name}
                project={project.name}
              />
            </React.Suspense>

            {project.description.trim() !== "" && (
              <SingleProjectSummary content={project.description} />
            )}

            <section class="single-work-page">
              <Row>
                <Col md={6} className="column px-0">
                  <SingleWorkDesign1ForOddItems children={data} />
                </Col>
                <Col md={6} className="column px-0">
                  <SingleWorkDesign1ForEvenItems children={data} />
                </Col>
              </Row>
            </section>
          </>
        );
      } else {
        return (
          <>
            <React.Suspense fallback={<CenterLoader />}>
              <SingleWorkPageBreadCrumb
                category={project.category_name}
                project={project.name}
              />
            </React.Suspense>

            {project.description.trim() !== "" && (
              <SingleProjectSummary content={project.description} />
            )}

            <section class="single-work-page">
              <SingleWorkDesign1ForMobile children={data} />
            </section>
          </>
        );
      }
    } else if (project.design_type == 2) {
      return (
        <>
          <React.Suspense fallback={<CenterLoader />}>
            <SingleWorkPageBreadCrumb
              category={project.category_name}
              project={project.name}
            />
          </React.Suspense>
          {project.description.trim() !== "" && (
            <SingleProjectSummary content={project.description} />
          )}
          <section class="single-work-page single-work-page-design-2">
            <Row>
              <Suspense fallback={<CenterLoader />}>
                <SingleWorksDesign2 project={data} />
              </Suspense>
            </Row>
          </section>
        </>
      );
    } else if (project.design_type == 3) {
      return (
        <>
          <React.Suspense fallback={<CenterLoader />}>
            <SingleWorkPageBreadCrumb
              category={project.category_name}
              project={project.name}
            />
          </React.Suspense>
          {project.description.trim() !== "" && (
            <SingleProjectSummary content={project.description} />
          )}
          <section class="single-work-page single-work-page-design-3">
            <Row>
              <Suspense fallback={<CenterLoader />}>
                <SingleWorksDesign3 project={data} />
              </Suspense>
            </Row>
          </section>
          <TalkToUs />;
        </>
      );
    } else if (project.design_type == 4) {
      return (
        <>
          <React.Suspense fallback={<CenterLoader />}>
            <SingleWorkPageBreadCrumb
              category={project.category_name}
              project={project.name}
            />
          </React.Suspense>

          {project.description.trim() !== "" && (
            <SingleProjectSummary content={project.description} />
          )}

          <section class="single-work-page single-work-page-design-4">
            <Row>
              <Suspense fallback={<CenterLoader />}>
                <SingleWorksDesign4 project={data} />
              </Suspense>
            </Row>
          </section>
          {/* <TalkToUs />; */}
        </>
      );
    } else {
      return (
        <>
          <React.Suspense fallback={<CenterLoader />}>
            <SingleWorkPageBreadCrumb
              category={project.category_name}
              project={project.name}
            />
          </React.Suspense>

          {project.description != "" && (
            <SingleProjectSummary content={project.description} />
          )}

          <section class="single-work-page single-work-page-design-5">
            <Row>
              <Suspense fallback={<CenterLoader />}>
                <SingleWorksDesign5 project={data} />
              </Suspense>
            </Row>
          </section>
          {/* <TalkToUs />; */}
        </>
      );
    }
  }
}
