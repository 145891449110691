import React from "react";
import {
  get_single_work_design_3_item_class,
  get_windows_dimention,
} from "../../helper";
import { Col } from "react-bootstrap";
import { APP_IMG_BASE_URL } from "../../ApiRoutes";
import VideoPlayer from "../sub-components/VideoPlayer";
import "./SingleWorksDesign3.css";
import TalkToUs from "../TalkToUs";
import CenterLoader from "../sub-components/CenterLoader";

export default function SingleWorksDesign3(props) {
  //   const project = props.project;
  const dimenstion = get_windows_dimention();

  // const project = [
  //   {
  //     thumb: "",
  //     desc: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document   ",
  //     media_type: "text",
  //   },
  //   {
  //     thumb:
  //       "http://localhost/animal_advertizing/site/app/server/assets/uploads/project/vid3.mp4",
  //     desc: "               ",
  //     media_type: "video",
  //   },

  //   {
  //     thumb: "/assets/uploads/project/1705472326_82aff78c2c445b708a78.jpg",
  //     desc: "             In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document  ",
  //     media_type: "image",
  //   },

  //   {
  //     thumb: "/assets/uploads/project/1705472326_d03ffb95e7b923aa23d1.jpg",
  //     desc: "             In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document  ",
  //     media_type: "image",
  //   },

  //   {
  //     thumb: "",
  //     desc: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document   ",
  //     media_type: "text",
  //   },

  //   {
  //     thumb: "/assets/uploads/project/1705472326_82aff78c2c445b708a78.jpg",
  //     desc: "             In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document  ",
  //     media_type: "image",
  //   },

  //   {
  //     thumb: "/assets/uploads/project/1705472326_d03ffb95e7b923aa23d1.jpg",
  //     desc: "             In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document  ",
  //     media_type: "image",
  //   },
  //   {
  //     thumb: "/assets/uploads/project/1705472326_82aff78c2c445b708a78.jpg",
  //     desc: "             In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document  ",
  //     media_type: "image",
  //   },

  //   {
  //     thumb: "/assets/uploads/project/1705472326_d03ffb95e7b923aa23d1.jpg",
  //     desc: "             In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document  ",
  //     media_type: "image",
  //   },
  //   {
  //     thumb: "",
  //     desc: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document   ",
  //     media_type: "text",
  //   },
  //   {
  //     thumb:
  //       "http://localhost/animal_advertizing/site/app/server/assets/uploads/project/vid3.mp4",
  //     desc: "               ",
  //     media_type: "video",
  //   },

  //   {
  //     thumb: "/assets/uploads/project/1705472326_82aff78c2c445b708a78.jpg",
  //     desc: "             In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document  ",
  //     media_type: "image",
  //   },

  //   {
  //     thumb: "/assets/uploads/project/1705472326_d03ffb95e7b923aa23d1.jpg",
  //     desc: "             In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document  ",
  //     media_type: "image",
  //   },

  //   {
  //     thumb: "",
  //     desc: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document   ",
  //     media_type: "text",
  //   },

  //   {
  //     thumb: "/assets/uploads/project/1705472326_82aff78c2c445b708a78.jpg",
  //     desc: "             In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document  ",
  //     media_type: "image",
  //   },

  //   {
  //     thumb: "/assets/uploads/project/1705472326_d03ffb95e7b923aa23d1.jpg",
  //     desc: "             In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document  ",
  //     media_type: "image",
  //   },
  //   {
  //     thumb: "/assets/uploads/project/1705472326_82aff78c2c445b708a78.jpg",
  //     desc: "             In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document  ",
  //     media_type: "image",
  //   },

  //   {
  //     thumb: "/assets/uploads/project/1705472326_d03ffb95e7b923aa23d1.jpg",
  //     desc: "             In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document  ",
  //     media_type: "image",
  //   },
  // ];

  const project = props.project;
  if (project != null && project.length > 0) {
    var designIndex = -1;
    if (dimenstion.width >= 768) {
      return project.map((item, index) => {
        designIndex++;
        console.log(designIndex);
        if (index == 8) {
          designIndex = 0;
        }
        return (
          <Col
            className={`${get_single_work_design_3_item_class(designIndex)}`}
          >
            {item.media_type == "image" && (
              <SingleWorksDesign3ImageItem item={item} />
            )}
            {item.media_type == "text" && (
              <SingleWorksDesign3TextItem item={item} />
            )}
            {item.media_type == "video" && (
              <SingleWorksDesign3VideoItem
                item={item}
                videoControls={item.video_controls}
              />
            )}
          </Col>
        );
      });
    } else {
      return project.map((item, index) => {
        return (
          <Col className="col-12">
            <div class="item">
              {item.title.trim() != "" && <h3>{item.title.trim()}</h3>}
              {item.media_type == "image" && (
                <>
                  <img
                    src={`${APP_IMG_BASE_URL}/${item.thumb}`}
                    className="img"
                    alt="img"
                  />
                  {item.desc.trim() !== "" && (
                    <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
                  )}
                </>
              )}

              {item.media_type == "video" && (
                <>
                  <VideoPlayer
                    classes="work-video"
                    src={`${item.thumb}`}
                    videoControls={item.video_controls}
                  />
                  {item.desc.trim() !== "" && (
                    <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
                  )}
                </>
              )}

              {item.media_type == "text" && (
                <SingleWorksDesign3TextItem item={item} />
              )}

              {item.desc.trim() !== "" && (
                <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
              )}
            </div>
          </Col>
        );
      });
    }
  } else {
    return <CenterLoader />;
  }
}

function SingleWorksDesign3ImageItem(props) {
  const { item } = props;
  return (
    <div class="item">
      {item.title.trim() != "" && <h3>{item.title.trim()}</h3>}
      <img
        src={`${APP_IMG_BASE_URL}/${item.thumb}`}
        className="img"
        alt="img"
      />
      {item.desc.trim() !== "" && (
        <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
      )}
    </div>
  );
}

function SingleWorksDesign3VideoItem(props) {
  const { item } = props;
  return (
    <div class="item video-item">
      {item.title.trim() != "" && <h3>{item.title.trim()}</h3>}
      <VideoPlayer
        classes="work-video"
        src={`${item.thumb}`}
        videoControls={item.video_controls}
      />
      {item.desc.trim() !== "" && (
        <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
      )}
    </div>
  );
}

function SingleWorksDesign3TextItem(props) {
  const { item } = props;
  return (
    <div class="item">
      {item.title.trim() != "" && <h3>{item.title.trim()}</h3>}
      <div className="edit-text-item">
        {item.desc.trim() !== "" && (
          <div dangerouslySetInnerHTML={{ __html: item.desc }}></div>
        )}
      </div>
    </div>
  );
}
