import { Col, Row } from "react-bootstrap";
import TopTitleVidComp from "../TopTitleVidComp";
import "./../sub-components/HomePageGrid.css";
import HomepageGrid from "../sub-components/HomePageGrid";

export default function HomePage() {
  return (
    <>
      <TopTitleVidComp />
      {/* first section starts */}

      <HomepageGrid />
      {/* <section>
        <div class="section-wrap grid-section">
          <Row>
            <Col md={5} className="sec-item">
              <div className="content-wrap">
                <img src="images/home/img-1.png" className="w-100" />
                <Row>
                  <Col md={4} className="content-col">
                    <h2 className="sec-title">Cred</h2>
                  </Col>
                  <Col md={8} className="content-col">
                    <p className="sec-disc">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={7} className="sec-item">
              <div className="content-wrap">
                <img src="images/home/img-2.png" className="w-100" />
                <Row>
                  <Col md={{ span: 4 }} className="content-col">
                    <h2 className="sec-title">
                      Budweiser
                      <br />
                      Made Over
                      <br />
                      Nights
                    </h2>
                  </Col>

                  <Col md={{ span: 8 }} className="content-col">
                    <p className="sec-disc">
                      lorem ipsum dolor sit amet, consectetuer
                      <br /> adipiscing elit, sed diam nonummy nibh euismod
                      <br />
                      tincidunt ut laoreet dolore magna aliquam
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col md={7} className="sec-item">
              <div className="content-wrap">
                <img src="images/home/img-3.png" className="w-100" />
                <Row>
                  <Col md={{ span: 4 }} className="content-col">
                    <h2 className="sec-title">
                      Bhima
                      <br />
                      Jewellary
                    </h2>
                  </Col>

                  <Col md={{ span: 8 }} className="content-col">
                    <p className="sec-disc">
                      lorem ipsum dolor sit amet, consectetuer
                      <br /> adipiscing elit, sed diam nonummy nibh euismod
                      <br />
                      tincidunt ut laoreet dolore magna aliquam
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col md={5} className="sec-item">
              <div className="content-wrap">
                <img src="images/home/img-4.png" className="w-100" />
                <Row>
                  <Col md={4} className="content-col">
                    <h2 className="sec-title">Airtel Black</h2>
                  </Col>
                  <Col md={8} className="content-col">
                    <p className="sec-disc">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col md={6} className="sec-item">
              <div className="content-wrap">
                <img src="images/home/img-5.png" className="w-100" />
                <Row>
                  <Col md={{ span: 4 }} className="content-col">
                    <h2 className="sec-title">Keventers </h2>
                  </Col>

                  <Col md={{ span: 8 }} className="content-col">
                    <p className="sec-disc">
                      lorem ipsum dolor sit amet, <br />
                      consectetuer adipiscing elit, sed <br />
                      diam nonummy nibh euismod
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col md={6} className="sec-item">
              <div className="content-wrap">
                <img src="images/home/img-6.png" className="w-100" />
                <Row>
                  <Col md={{ span: 4 }} className="content-col">
                    <h2 className="sec-title">Keventers </h2>
                  </Col>

                  <Col md={{ span: 8 }} className="content-col">
                    <p className="sec-disc">
                      lorem ipsum dolor sit amet, <br />
                      consectetuer adipiscing elit, sed <br />
                      diam nonummy nibh euismod
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </section> */}

      {/* first section ends */}

      {/* second section starts */}
      {/* <section>
        <div class="section-wrap grid-section">
          <Row>
            <Col md={7} className="sec-item">
              <img src="images/home/img-3.png" className="w-100" />
              <Row>
                <Col md={{ span: 4 }} className="content-col">
                  <h2 className="sec-title">
                    Bhima
                    <br />
                    Jewellary
                  </h2>
                </Col>

                <Col md={{ span: 8 }} className="content-col">
                  <p className="sec-disc">
                    lorem ipsum dolor sit amet, consectetuer
                    <br /> adipiscing elit, sed diam nonummy nibh euismod
                    <br />
                    tincidunt ut laoreet dolore magna aliquam
                  </p>
                </Col>
              </Row>
            </Col>

            <Col md={5} className="sec-item">
              <div className="content-wrap">
                <img src="images/home/img-4.png" className="w-100" />
                <Row>
                  <Col md={7} className="content-col">
                    <h2 className="sec-title">
                      Airtel
                      <br />
                      Black
                    </h2>
                  </Col>
                  <Col md={5} className="content-col">
                    <p className="sec-disc">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </section> */}

      {/* second section ends */}

      {/* third section starts */}
      {/* <section>
        <div class="section-wrap grid-section">
          <Row>
            <Col md={6} className="sec-item">
              <img src="images/home/img-5.png" className="w-100" />
              <Row>
                <Col md={{ span: 5 }} className="content-col">
                  <h2 className="sec-title">Keventers </h2>
                </Col>

                <Col md={{ span: 7 }} className="content-col">
                  <p className="sec-disc">
                    lorem ipsum dolor sit amet, <br />
                    consectetuer adipiscing elit, sed <br />
                    diam nonummy nibh euismod
                  </p>
                </Col>
              </Row>
            </Col>

            <Col md={6} className="sec-item">
              <div className="content-wrap">
                <img src="images/home/img-6.png" className="w-100" />
                <Row>
                  <Col md={{ span: 5 }} className="content-col">
                    <h2 className="sec-title">Keventers </h2>
                  </Col>

                  <Col md={{ span: 7 }} className="content-col">
                    <p className="sec-disc">
                      lorem ipsum dolor sit amet, <br />
                      consectetuer adipiscing elit, sed <br />
                      diam nonummy nibh euismod
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </section> */}

      {/* third section ends */}
    </>
  );
}
