import { Nav, NavLink } from "react-bootstrap";
import { Link, NavLink as NaviLink } from "react-router-dom";

export default function HeaderMenu() {
  return (
    <div className="header-menu w-100">
      <Nav className="d-flex justify-content-evenly list-style-none mb-0 px-0 flex-shrink-1">
        <li>
          <NavLink
            as={NaviLink}
            to="/"
            className={({ isActive }) => console(isActive)}
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            as={NaviLink}
            to="/work"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Work
          </NavLink>
        </li>
        <li>
          <NavLink
            as={NaviLink}
            to="/about-us"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            About us
          </NavLink>
        </li>
      </Nav>
    </div>
  );
}
