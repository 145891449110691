import ReactPlayer from "react-player";
import VideoPlayer from "../sub-components/VideoPlayer";
import { useState } from "react";
import { Row } from "react-bootstrap";
import Loader from "../sub-components/Loader";
import CenterLoader from "../sub-components/CenterLoader";

export default function AboutPage() {
  const data = [
    // {
    //   src: "https://vimeo.com/817656303",
    // },
    // {
    //   src: "https://vimeo.com/817656303",
    // },
    // {
    //   src: "https://vimeo.com/76132202",
    // },
    {
      src: "https://youtu.be/sNqfQZI9WdU",
    },
  ];

  return (
    <section>
      <h2 className="text-center">Under development</h2>

      <CenterLoader />
      {/* <ReactPlayer
        controls={true}
        onMouseOver={function (event) {
          event.target.play();
          console.log(event.target);
        }}
        url="https://vimeo.com/817656303"
      /> */}

      {/* <Row>
        {data.map((item, index) => {
          return <VideoPlayer url={item.src} />;
        })}
      </Row> */}
    </section>
  );
}
