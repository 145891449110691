import axios from "axios";
import { useEffect, useState } from "react";
import {
  API_BASE_URL,
  APP_IMG_BASE_URL,
  HOME_PAGE_PROJECT_URL,
} from "../../ApiRoutes";
import { Col, Row } from "react-bootstrap";
import { get_home_page_grid_project_class } from "../../helper";
import { Link } from "react-router-dom";

export default function HomepageGrid() {
  console.log(HOME_PAGE_PROJECT_URL);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(0);
  useEffect(function () {
    axios
      .get(API_BASE_URL + "home-projects")
      .then((res) => {
        console.log(res.data);
        setData(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  let designIndex = -1;
  return (
    <section>
      <div class="section-wrap grid-section">
        <Row>
          {data.map((item, index) => {
            designIndex++;

            if (designIndex == 6) designIndex = 0;
            return (
              <Col
                className={`sec-item ${get_home_page_grid_project_class(
                  designIndex
                )}`}
              >
                <Link to={`/work/project/${item.slug}`}>
                  <div className="content-wrap">
                    <img
                      src={`${APP_IMG_BASE_URL}/${item.thumbnail}`}
                      className="w-100"
                    />
                    <Row>
                      <Col md={4} className="content-col">
                        <h2 className="sec-title">{item.name}</h2>
                      </Col>
                      <Col md={8} className="content-col">
                        <p className="sec-disc">
                          {item.description.length > 90
                            ? item.description.substr(0, 90) + "..."
                            : item.description}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Link>
              </Col>
            );
          })}
        </Row>
      </div>
    </section>
  );
}
