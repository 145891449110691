import React from "react";
import {
  get_single_work_design_5_item_class,
  get_windows_dimention,
  get_type_wrap_class,
} from "../../helper";
import { Col } from "react-bootstrap";
import { APP_IMG_BASE_URL } from "../../ApiRoutes";
import VideoPlayer from "../sub-components/VideoPlayer";
import "./SingleWorksDesign5.css";
import TalkToUs from "../TalkToUs";
import CenterLoader from "../sub-components/CenterLoader";

export default function SingleWorksDesign5(props) {
  //   const project = props.project;
  const dimenstion = get_windows_dimention();

  const project = props.project;
  if (project != null && project.length > 0) {
    var designIndex = -1;
    if (dimenstion.width >= 768) {
      return project.map((item, index) => {
        designIndex++;

        if (index >= 46 && index % 46 == 0) {
          designIndex = 0;
        }
        return (
          <Col
            className={`${get_single_work_design_5_item_class(designIndex)}`}
          >
            {item.media_type == "image" && (
              <SingleWorksDesign5ImageItem item={item} />
            )}
            {item.media_type == "text" && (
              <SingleWorksDesign5TextItem item={item} />
            )}
            {item.media_type == "video" && (
              <SingleWorksDesign5VideoItem
                item={item}
                videoControls={item.video_controls}
              />
            )}
          </Col>
        );
      });
    } else {
      return project.map((item, index) => {
        return (
          <Col className="col-12">
            <div class={`item ${get_type_wrap_class(item.media_type)}`}>
              {item.title.trim() != "" && <h3>{item.title.trim()}</h3>}
              {item.media_type == "image" && (
                <>
                  <img
                    src={`${APP_IMG_BASE_URL}/${item.thumb}`}
                    className="img"
                    alt="img"
                  />
                  {item.desc.trim() !== "" && (
                    <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
                  )}
                </>
              )}

              {item.media_type == "video" && (
                <>
                  <VideoPlayer
                    classes="work-video"
                    src={`${item.thumb}`}
                    videoControls={item.video_controls}
                  />
                  {item.desc.trim() !== "" && (
                    <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
                  )}
                </>
              )}

              {item.media_type == "text" && (
                <SingleWorksDesign5TextItem item={item} />
              )}

              {item.desc.trim() !== "" && (
                <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
              )}
            </div>
          </Col>
        );
      });
    }
  } else {
    return <CenterLoader />;
  }
}

function SingleWorksDesign5ImageItem(props) {
  const { item } = props;
  return (
    <div class={`item ${get_type_wrap_class(item.media_type)}`}>
      {item.title.trim() != "" && <h3>{item.title.trim()}</h3>}
      <img
        src={`${APP_IMG_BASE_URL}/${item.thumb}`}
        className="img"
        alt="img"
      />
      {item.desc.trim() !== "" && (
        <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
      )}
    </div>
  );
}

function SingleWorksDesign5VideoItem(props) {
  const { item } = props;
  return (
    <div class={`item item video-item ${get_type_wrap_class(item.media_type)}`}>
      {item.title.trim() != "" && <h3>{item.title.trim()}</h3>}
      <VideoPlayer
        classes="work-video"
        src={`${item.thumb}`}
        videoControls={item.video_controls}
      />
      {item.desc.trim() !== "" && (
        <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
      )}
    </div>
  );
}

function SingleWorksDesign5TextItem(props) {
  const { item } = props;
  return (
    <div class={`item ${get_type_wrap_class(item.media_type)}`}>
      {item.title.trim() != "" && <h3>{item.title.trim()}</h3>}
      <div className="edit-text-item">
        {item.desc.trim() !== "" && (
          <div dangerouslySetInnerHTML={{ __html: item.desc }}></div>
        )}
      </div>
    </div>
  );
}
