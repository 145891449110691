import "./HamburgerIcon.css";

export default function HamburgerIcon() {
  return (
    <div className="icon-wrap ms-auto">
      <div className="hamburger-icon">
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </div>
  );
}
