import ReactPlayer from "react-player";
import VideoPlayer from "../sub-components/VideoPlayer";
import { useState } from "react";
import { Row } from "react-bootstrap";
import Loader from "../sub-components/Loader";
import CenterLoader from "../sub-components/CenterLoader";
import "./PageNotFound.css";

export default function PageNotFound() {
  return (
    <section className="page-not-found">
      <p className="text-center" id="big-title">
        404
      </p>
      <h2 className="text-white text-center mb-5">Page not found</h2>
      <h4 className="text-white text-center">
        We think you have lost in jungle. Go back and find a way
      </h4>
    </section>
  );
}
