export default function AddressWidget(props) {
  return (
    <div className="widget">
      <div className="address-widget">
        {props.time && <div className="widget-title mb-4">{props.time}</div>}

        {props.address && <address>{props.address}</address>}

        {props.email && <span className="address-mail">{props.email}</span>}
      </div>
    </div>
  );
}
