/* 
    * helper.js
    contains helper functions
*/

export function getItemClass(col, layout = 1) {
  if (layout == 1) {
    switch (col) {
      case 0:
        return "col-md-6";
        break;
      case 1:
        return "col-md-6";
        break;
      case 2:
        return "col-md-12";
        break;

      case 3:
        return "col-md-12";
        break;

      case 4:
        return "col-md-12";
        break;
      case 5:
        return "col-md-12";
        break;

      case 6:
        return "col-md-12";
        break;
      case 7:
        return "col-md-12";
        break;
      case 8:
        return "col-md-12";
        break;

      case 9:
        return "col-md-6";
        break;

      case 10:
        return "col-md-6";
        break;
      case 11:
        return "col-md-6";
        break;
      case 12:
        return "col-md-12";
        break;
      case 13:
        return "col-md-12";
        break;
      case 14:
        return "col-md-12";
        break;
      case 15:
        return "col-md-12";
        break;
      case 16:
        return "col-md-12";
        break;
      case 17:
        return "col-md-12";
        break;

      default:
        return "col-12";
    }
  }
}

export function get_windows_dimention() {
  const { clientWidth: width, clientHeight: height } = document.body;
  return {
    width,
    height,
  };
}

// function to generate class for single work design 2
export function get_single_work_design_2_item_class(index) {
  switch (index) {
    case 0:
      return "col-md-12";
      break;
    case 1:
      return "col-md-4 right-pad";
      break;
    case 2:
      return "col-md-8 left-pad";
      break;
    case 3:
      return "col-md-8 right-pad";
      break;
    case 4:
      return "col-md-4 left-pad";
      break;
    case 5:
      return "col-md-4 right-pad";
      break;
    case 6:
      return "col-md-8 left-pad";
      break;
    case 7:
      return "col-md-12";
      break;
    default:
      return "col-md-12";
  }
}

// function to generate class for single work design 3
export function get_single_work_design_3_item_class(index) {
  switch (index) {
    // case 0:
    //   return "col-md-12";
    //   break;
    case 0:
      return "col-md-12";
      break;
    case 1:
      return "col-md-4 right-pad";
      break;
    case 2:
      return "col-md-8 left-pad";
      break;
    case 3:
      return "col-md-12";
      break;
    case 4:
      return "col-md-8 right-pad";
      break;
    case 5:
      return "col-md-4 left-pad";
      break;
    case 6:
      return "col-md-4 right-pad";
      break;
    case 7:
      return "col-md-8 left-pad";
      break;

    default:
      return "col-md-12";
  }
}

// to generate class for single work design 4
export function get_single_work_design_4_item_class(index) {
  switch (index) {
    // case 0:
    //   return "col-md-12";
    //   break;
    case 0:
      return "col-md-12";
      break;
    case 1:
      return "col-md-12";
      break;
    case 2:
      return "col-md-4 right-pad";
      break;
    case 3:
      return "col-md-8 left-pad";
      break;
    case 4:
      return "col-md-7 right-pad";
      break;
    case 5:
      return "col-md-5 left-pad";
      break;
    case 6:
      return "col-md-4 right-pad 7";
      break;
    case 7:
      return "col-md-8 left-pad";
      break;

    case 8:
      return "col-md-8 right-pad";
      break;
    case 9:
      return "col-md-4 left-pad";
      break;

    case 10:
      return "col-md-7 right-pad";
      break;
    case 11:
      return "col-md-5 left-pad";
      break;

    case 12:
      return "col-md-12";
      break;

    case 13:
      return "col-md-8 right-pad";
      break;
    case 14:
      return "col-md-4 left-pad";
      break;

    case 15:
      return "col-md-37-5 right-pad";
      break;
    case 16:
      return "col-md-24-5 left-pad right-pad";
      break;
    case 17:
      return "col-md-37-5 left-pad";
      break;

    case 18:
      return "col-md-12";
      break;
    case 19:
      return "col-md-12";
      break;
    case 20:
      return "col-md-12";
      break;

    default:
      return "col-md-12";
  }
}

export function get_type_wrap_class(type = "") {
  switch (type) {
    case "image":
      return "type-img-wrap";
      break;
    case "video":
      return "type-video-wrap";
      break;
    case "text":
      return "type-text-wrap";
      break;
    default:
      return "";
  }
}

// to generate class for single work design 5
export function get_single_work_design_5_item_class(index) {
  switch (index) {
    // case 0:
    //   return "col-md-12";
    //   break;
    case 0:
      return "col-md-6 right-pad";
      break;
    case 1:
      return "col-md-6 left-pad";
      break;
    case 2:
      return "col-md-12";
      break;
    case 3:
      return "col-md-4 right-pad";
      break;
    case 4:
      return "col-md-4 left-pad right-pad";
      break;
    case 5:
      return "col-md-4 left-pad";
      break;
    case 6:
      return "col-md-4 right-pad";
      break;
    case 7:
      return "col-md-4 right-pad left-pad";
      break;

    case 8:
      return "col-md-4 left-pad";
      break;
    case 9:
      return "col-md-6 right-pad";
      break;

    case 10:
      return "col-md-6 left-pad";
      break;
    case 11:
      return "col-md-4 right-pad";
      break;

    case 12:
      return "col-md-4 left-pad right-pad";
      break;

    case 13:
      return "col-md-4 left-pad";
      break;
    case 14:
      return "col-md-6 right-pad";
      break;

    case 15:
      return "col-md-6 left-pad";
      break;
    case 16:
      return "col-md-4 right-pad";
      break;
    case 17:
      return "col-md-4 right-pad left-pad";
      break;

    case 18:
      return "col-md-4 left-pad";
      break;
    case 19:
      return "col-md-4 right-pad";
      break;
    case 20:
      return "col-md-4 left-pad right-pad";
      break;
    case 21:
      return "col-md-4 left-pad";
      break;
    case 22:
      return "col-md-6 right-pad";
      break;
    case 23:
      return "col-md-6 left-pad";
      break;
    case 24:
      return "col-md-4 right-pad";
      break;
    case 25:
      return "col-md-4 left-pad right-pad";
      break;

    case 26:
      return "col-md-4 left-pad";
      break;
    case 27:
      return "col-md-6 right-pad 28";
      break;
    case 28:
      return "col-md-6 left-pad";
      break;

    case 29:
      return "col-md-4 right-pad";
      break;
    case 30:
      return "col-md-4 right-pad left-pad";
      break;
    case 31:
      return "col-md-4 left-pad";
      break;
    case 32:
      return "col-md-4 right-pad";
      break;

    case 33:
      return "col-md-4 left-pad right-pad";
      break;

    case 34:
      return "col-md-4 left-pad";
      break;
    case 35:
      return "col-md-6 right-pad";
      break;
    case 36:
      return "col-md-6 left-pad";
      break;

    case 37:
      return "col-md-4 right-pad ";
      break;

    case 38:
      return "col-md-4 left-pad right-pad";
      break;

    case 39:
      return "col-md-4 left-pad";
      break;
    case 40:
      return "col-md-6 right-pad ";
      break;
    case 41:
      return "col-md-6 left-pad";
      break;
    case 42:
      return "col-md-4 right-pad";
      break;
    case 43:
      return "col-md-4 right-pad left-pad";
      break;
    case 44:
      return "col-md-4 left-pad";
      break;
    case 45:
      return "col-md-4 left-pad";
      break;

    default:
      return "col-md-12";
  }
}

/* 

export function get_grid_row_css(count) {
  switch (count) {
    case 1:
      return `grid-template-rows: 600px;`;
      break;
    case 2:
      return `grid-template-rows: 600px 600px;`;
      break;
    case 3:
      return `grid-template-rows: 600px 600px 450px;`;
      break;
    case 4:
      return `grid-template-rows: 600px 600px 450px 450px;`;
      break;
    case 5:
      return `grid-template-rows: 600px 600px 450px 450px 700px;`;
      break;
    case 6:
      return `grid-template-rows: 600px 600px 450px 450px 700px 450px;`;
      break;
    case 7:
      return `grid-template-rows: 600px 600px 450px 450px 700px 450px 600px;`;
      break;
    case 8:
      return `grid-template-rows: 600px 600px 450px 450px 700px 450px 600px 400px`;
      break;
    case 9:
      return `grid-template-rows: 600px 600px 450px 450px 700px 450px 600px 400px 400px;`;
      break;
    case 10:
      return `grid-template-rows: grid-template-rows: 600px 600px 450px 450px 700px 450px 600px 400px 400px 500px;`;
      break;
    default:
      return ``;
  }
}
*/

/* 
  grid-template-rows: 600px 600px 450px 450px 700px 450px 600px 400px 400px 500px;
*/

export function get_grid_row_css(count) {
  switch (count) {
    case 1:
      return `grid-template-rows: 600px;`;
      break;
    case 2:
      return `grid-template-rows: 600px;`;
      break;

    case 3:
      return `grid-template-rows: 600px 600px`;
      break;

    case 4:
      return `grid-template-rows: 600px 600px 450px 450px;`;
      break;

    case 5:
      return `grid-template-rows: 600px 600px 450px 450px 700px;`;
      break;

    case 6:
      return `grid-template-rows: 600px 600px 450px 450px 700px`;
      break;

    case 7:
      return `grid-template-rows: 600px 600px 450px 450px 700px 450px;`;
      break;
    case 8:
      return `grid-template-rows: 600px 600px 450px 450px 700px 450px;`;
      break;

    case 9:
      return `grid-template-rows: 600px 600px 450px 450px 700px 450px;`;
      break;

    case 10:
      return `grid-template-rows:600px 600px 450px 450px 700px 450px 600px`;
      break;

    case 11:
      return `grid-template-rows: 600px 600px 450px 450px 700px 450px 600px 400px;`;
      break;

    case 12:
      return `grid-template-rows: 600px 600px 450px 450px 700px 450px 600px 400px;`;
      break;

    case 13:
      return `grid-template-rows: 600px 600px 450px 450px 700px 450px 600px 400px;`;
      break;
    case 14:
      return `grid-template-rows: 600px 600px 450px 450px 700px 450px 600px 400px 400px 500px;`;
      break;
    case 15:
      return `grid-template-rows: 600px 600px 450px 450px 700px 450px 600px 400px 400px 500px;`;
      break;
    case 16:
      return `grid-template-rows:600px 600px 450px 450px 700px 450px 600px 400px 400px 500px;`;
      break;
    case 17:
      return `grid-template-rows: 600px 600px 450px 450px 700px 450px 600px 400px 400px 500px;`;
      break;
    case 18:
      return `grid-template-rows: 600px 600px 450px 450px 700px 450px 600px 400px 400px 500px;`;
      break;
    default:
      return ``;
  }
}

export function get_home_page_grid_project_class(index) {
  switch (index) {
    case 0:
      return "col-md-5";
      break;

    case 1:
      return "col-md-7";
      break;

    case 2:
      return "col-md-7";
      break;

    case 3:
      return "col-md-5";
      break;

    case 4:
      return "col-md-6";
      break;

    case 5:
      return "col-md-6";
      break;

    default:
      return "col-md-5";
  }
}
