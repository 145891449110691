import React from "react";
import "./SingleWorkDesign1.css";
import { APP_IMG_BASE_URL } from "../../ApiRoutes";
import VideoPlayer from "../sub-components/VideoPlayer";

export function SingleWorkDesign1ForOddItems(props) {
  const data = props.children;

  let counter = 0;
  return (
    <section
      className="single-work-design two-column-design"
      key={`single-work-des-two-col-1`}
    >
      {data.map((item, index) => {
        return ++counter % 2 != 0 && <SingleItem item={item} />;
      })}
    </section>
  );
}

function SingleItem(props) {
  const { item } = props;

  if (item.media_type == "image") {
    return (
      <div className="item">
        {item.title.trim() != "" && <h3>{item.title.trim()}</h3>}
        <img src={`${APP_IMG_BASE_URL}/${item.thumb}`} />
        {item.desc.trim() != "" && (
          <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
        )}
      </div>
    );
  } else if (item.media_type == "video") {
    return (
      <div className="item">
        {item.title.trim() != "" && <h3>{item.title.trim()}</h3>}
        <VideoPlayer
          classes="work-video"
          src={`${item.thumb}`}
          videoControls={item.video_controls}
        />
        {item.desc.trim() != "" && (
          <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
        )}
      </div>
    );
  } else {
    return (
      <div class="item">
        {item.title.trim() != "" && <h3>{item.title.trim()}</h3>}
        <div className="edit-text-item">
          {item.desc.trim() !== "" && (
            <div dangerouslySetInnerHTML={{ __html: item.desc }}></div>
          )}
        </div>
      </div>
    );
  }
}

export function SingleWorkDesign1ForEvenItems(props) {
  const data = props.children;
  let counter = 0;
  return (
    <section
      className="single-work-design two-column-design"
      key={`single-work-des-two-col-2`}
    >
      {data.map((item, index) => {
        return ++counter % 2 == 0 && <SingleItem item={item} />;
      })}
    </section>
  );
}

export function SingleWorkDesign1ForMobile(props) {
  const data = props.children;

  return (
    <section className="single-work-design single-work-design-mobile">
      {data.map((item, index) => {
        return (
          <div className="item">
            {item.title.trim() != "" && <h3>{item.title.trim()}</h3>}
            {item.design_type == "image" && (
              <img src={`${APP_IMG_BASE_URL}/${item.thumb}`} />
            )}

            {item.design_type == "video" && (
              <VideoPlayer
                classes="work-video"
                src={`${item.thumb}`}
                videoControls={item.video_controls}
              />
            )}

            {item.design_type == "text" && (
              <div className="edit-text-item">
                {item.desc.trim() !== "" && (
                  <div dangerouslySetInnerHTML={{ __html: item.desc }}></div>
                )}
              </div>
            )}

            {item.desc.trim() != "" && (
              <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>
            )}
          </div>
        );
      })}
    </section>
  );
}
