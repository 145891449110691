import { Col, Row } from "react-bootstrap";
import AddressWidget from "./AddressWidget";
import SubscriptionFormWidget from "./SubscriptionFormWidget";
import SocialShareLinks from "./SocialShareLinks";

export default function Footer() {
  return (
    <footer>
      <div className="section-wrap">
        <Row>
          <Col md={6}>
            {/* <div className="widget">
              <div className="address-widget">
                <div className="widget-title mb-4">
                  New Delhi, India <br />
                  10.00 AM
                </div>
                <address>
                  Floor 1. Sultan Sadan-1. Lane 3. Westend
                  <br />
                  Marg. Saidulajaib. New Delhi 110030. India
                </address>
                <span class="address-mail">del@weareanimal.co</span>
              </div>
            </div> */}

            <AddressWidget
              key="address-widget-1"
              time={["New Delhi, India", <br />, "10.00 AM"]}
              address={[
                "Floor 1. Sultan Sadan-1. Lane 3. Westend",
                <br />,
                "Marg. Saidulajaib. New Delhi 110030. India",
              ]}
              email="del@weareanimal.co"
            />

            <SubscriptionFormWidget />

            <div className="copyright">&copy; 2023 Animal</div>
          </Col>
          <Col md={6}>
            <AddressWidget
              key="address-widget-2"
              time={["New Delhi, India", <br />, "10.00 AM"]}
              address={[
                "Lorem ipsum dolor sit amet, consectetuer adipiscing elit",
                <br />,
                "sed diam nonummy nibh euismod",
              ]}
              email="volutpat. Ut wisi enim ad minim"
            />

            <SocialShareLinks />
          </Col>
        </Row>
      </div>
    </footer>
  );
}
