import { Nav, NavLink } from "react-bootstrap";
import "./WorkPageBreadCrumb.css";
import { Link, NavLink as NaviLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { CATEGORY_MENU_URL } from "../../ApiRoutes";
import axios from "axios";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function WorkPageBreadCrumb(props) {
  const [menuData, setMenuData] = useState([]);

  useEffect(() => {
    axios.get(CATEGORY_MENU_URL).then((res) => {
      setMenuData(res.data.data);
    });
  }, []);

  const settings = {
    className: "slider variable-width",

    dots: false,
    infinite: false,
    centerMode: false,
    // focusOnSelect: true,
    swipeToSlide: true,
    slidesToScroll: 2,
    variableWidth: true,
    slidesToShow: 3,
    arrows: false,
  };

  // const menuItems = [
  //   {
  //     name: "Identity",
  //     slug: "identity",
  //   },
  //   {
  //     name: "Identity",
  //     slug: "identity",
  //   },
  //   {
  //     name: "Communications",
  //     slug: "communication",
  //   },
  //   {
  //     name: "Digital",
  //     slug: "digital",
  //   },
  //   {
  //     name: "Packaging",
  //     slug: "packaging",
  //   },
  //   {
  //     name: "Identity",
  //     slug: "identity",
  //   },
  //   {
  //     name: "Identity",
  //     slug: "identity",
  //   },
  //   {
  //     name: "Communications",
  //     slug: "communication",
  //   },
  //   {
  //     name: "Digital",
  //     slug: "digital",
  //   },
  //   {
  //     name: "Packaging",
  //     slug: "packaging",
  //   },
  // ];

  return (
    <Nav className="tabs-nav">
      <div className="parent_page position-relative">
        <NavLink as={NaviLink} to="/work">
          <span className="parent d-inline-block">Work</span>
        </NavLink>
        <span className="parent-sep"></span>
      </div>

      <ul className="work-category-menu-wrap">
        <li key={`work-category-${0}`}>
          <NavLink
            as={NaviLink}
            to="/work/all"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            All
          </NavLink>
        </li>

        {menuData.map((item, index) => {
          return (
            <li key={`work-category-${item.id}`} className="d-block">
              <NavLink
                as={NaviLink}
                to={`/work/${item.slug}`}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                {item.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </Nav>
  );
}
