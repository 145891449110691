import { Col, Container, NavLink, Row } from "react-bootstrap";
import HeaderMenu from "./HeaderMenu";
import HamburgerIcon from "./HamburgerIcon";

export default function Header() {
  const menuItems = new Map([
    ["Home", "/"],
    ["Work", "/work"],
    ["About us", "/about-us"],
  ]);

  return (
    <header>
      <div className="section-wrap">
        <Container fluid>
          <Row className="flex-nowrap">
            <Col>
              <div id="header-logo">
                <NavLink href="/">
                  <img src="/logo.png" className="img-fluid" />
                </NavLink>
              </div>
            </Col>
            <Col md={9} className="d-flex align-items-md-center">
              <HeaderMenu />
            </Col>
            <Col>
              <HamburgerIcon />
            </Col>
          </Row>
        </Container>
      </div>
    </header>
  );
}
