export default function SubscriptionFormWidget(props) {
  return (
    <div className="widget">
      <div className="subscription-form-widget">
        <p className="mb-4">{props.title}</p>

        <form id="newsLetterForm">
          <div className="d-flex field-wrap">
            <input type="email" name="email" id="email" placeholder="Email:" />
            <input type="submit" value="Signup" />
          </div>
        </form>
      </div>
    </div>
  );
}
